import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { MODALS_SIZE } from 'src/models/modal';
import { MouseEvent, useEffect } from 'react';
import { getPaymentAction, refundPaymentAction } from 'src/redux/actions/paymentActions';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
// eslint-disable-next-line import/no-extraneous-dependencies

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
}));

const RefundPaymentForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const paymentId = useSelector(
    (state: Store) => {
      if (!state.dialog[DIALOGS.refundPayments]) return 0;
      if (!state.dialog[DIALOGS.refundPayments].payload) return 0;
      return state.dialog[DIALOGS.refundPayments].payload;
    },
  );

  useEffect(() => {
    dispatch(getPaymentAction(paymentId as number));
  }, []);

  const handleClose = () => {
    dispatch(closeDialog({ dialog: DIALOGS.refundPayments }));
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(refundPaymentAction(paymentId));
    return true;
  };

  return (
    <form className={classes.form} noValidate>
      <div className={classes.row}>
        Are you sure you want to refind this payment?
      </div>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
        >
          {t(lang, 'misc.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t(lang, 'misc.refund_payments')}
        </Button>
      </div>
    </form>
  );
};

export default RefundPaymentForm;
