import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import EditPaymentButton from 'src/components/DatagridToolbar/buttons/EditPaymentButton';
import RefundPaymentButton from 'src/components/DatagridToolbar/buttons/RefundPaymentButton';
import CancelPaymentButton from 'src/components/DatagridToolbar/buttons/CancelPaymentButton';
import ConfigureColumnsButton from 'src/components/DatagridToolbar/buttons/ConfigureColumnsButton';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import UnselectAllMenubutton from 'src/components/DatagridToolbar/buttons/UnselectAllMenuButton';
import ExportPaymentButton from 'src/components/DatagridToolbar/buttons/ExportPaymentButton';
import DownloadPaymentButton from 'src/components/DatagridToolbar/buttons/DownloadPaymentButton';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';

const useStyles = makeStyles((theme: Theme) => ({
  actionMenuContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #eee',
    padding: theme.spacing(1),
    backgroundColor: '#f8f8f8',
  },
}));

const PaymentInvoiceToolbar = () => {
  const classes = useStyles();

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const getPaymentId = () => {
    if (!selections.length) return 0;
    return selections[0].paymentId;
  };

  return (
    <div className={`${classes.actionMenuContainer}`}>
      <EditPaymentButton
        disabled={!selections.length}
      />
      <RefundPaymentButton
        disabled={selections.length !== 1}
        paymentId={getPaymentId()}
      />
      <CancelPaymentButton
        disabled={!selections.length}
      />
      <ConfigureColumnsButton
        entity={ENTITIES.payments}
      />
      <UnselectAllMenubutton
        entity={ENTITIES.payments}
        disabled={!selections.length}
      />
      <ExportPaymentButton
        disabled={!selections.length}
      />
      <DownloadPaymentButton />
    </div>
  );
};

export default PaymentInvoiceToolbar;
